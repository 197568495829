/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useReducer, useContext } from "react";
import { Action, ContextProps, DataProviderProps, State } from "./utils/types";
import Actions from "./utils/constants";

const DataContext = createContext<ContextProps | undefined>(undefined);

const initialState: State = {
  data: null,
  profileData: null,
  userData: null,
};

const dataReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.FETCH_SALES_PARTNERS:
      return {
        ...state,
        data: action.payload,
      };
    case Actions.FETCH_USER_PROFILE_DETAILS:
      return {
        ...state,
        profileData: action.payload,
      };
    case Actions.FETCH_USER_DETAILS:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line react/function-component-definition
export const UtilityDataProvider: React.FC<DataProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};

export const useContextData = (): ContextProps => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useContextData must be used within a DataProvider");
  }
  return context;
};
