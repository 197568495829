import { PING_RESOURCE_URL, REQUEST_TIMEOUT } from "@utils/constants";

export const timeoutUnresolvedRequest = (
  time: number,
  promise: Promise<unknown>
) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);

    promise.then(resolve, reject);
  });
};

export const checkInternetConnection = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  if (!navigator?.onLine) {
    return false;
  }

  try {
    await timeoutUnresolvedRequest(
      REQUEST_TIMEOUT,
      fetch(PING_RESOURCE_URL, {
        method: "GET",
        signal,
      })
    );

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    controller.abort();
  }

  return false;
};

export const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });
