import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// * contexts
import { NetworkStatusProvider } from "@contexts/NetworkStatusContext";
import NotificationProvider from "@contexts/NotificationContext";

// * components
import PrivateRoutes from "@routes/PrivateRoutes";
import { UtilityDataProvider } from "@contexts/UtilityContext";

// * pages
const Application = lazy(() => import("@pages/Application"));
const Login = lazy(() => import("@pages/Login"));
const NotFound = lazy(() => import("@pages/NotFound"));

export default function App() {
  return (
    <NetworkStatusProvider>
      <NotificationProvider>
        <UtilityDataProvider>
          <Router>
            <Suspense>
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route
                  path={"/*"}
                  element={
                    <PrivateRoutes>
                      <Application />
                    </PrivateRoutes>
                  }
                />
                <Route path="/page-not-found" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/page-not-found" />} />
              </Routes>
            </Suspense>
          </Router>
        </UtilityDataProvider>
      </NotificationProvider>
    </NetworkStatusProvider>
  );
}
