import React from "react";
import { ISvgProps } from "@components/Svg/interface";

interface IDoneStatusProps extends ISvgProps {
  width?: string;
  height?: string;
}

export default function DoneStatus({
  width = "15",
  height = "15",
}: IDoneStatusProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_27398_116863)">
        <path
          d="M8 15.9969C12.4183 15.9969 16 12.4175 16 8.00215C16 3.58677 12.4183 0.00740051 8 0.00740051C3.58172 0.00740051 0 3.58677 0 8.00215C0 12.4175 3.58172 15.9969 8 15.9969Z"
          fill="#5A953E"
        />
        <path
          d="M7.35857 12.2525L3.57607 9.3057L4.65157 7.92545L6.97431 9.73495L10.8078 4.2032L12.2463 5.1997L7.35857 12.2525Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_27398_116863">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
